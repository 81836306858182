var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full bg-gray-100 overflow-hidden"},[_c('div',{staticClass:"flex flex-col space-y-2 w-2/3 mx-auto mt-4 bg-white px-8 py-4"},[_c('div',{staticClass:"text-lg text-gray-500"},[_vm._v("Manage all labels")]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('v-text-field',{class:[_vm.darkText ? 'dark-text-input' : 'light-text-input'],style:({
          'background-color': _vm.editLabel.color
        }),attrs:{"placeholder":"Type label text","outlined":"","dense":"","clearable":"","hide-details":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.createNewLabel.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.clearInput.apply(null, arguments)}]},model:{value:(_vm.editLabel.text),callback:function ($$v) {_vm.$set(_vm.editLabel, "text", $$v)},expression:"editLabel.text"}}),_c('color-picker',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":""}},on),[_c('v-icon',[_vm._v("mdi-format-color-fill")]),_c('span',[_vm._v("Background color")])],1)]}}]),model:{value:(_vm.editLabel.color),callback:function ($$v) {_vm.$set(_vm.editLabel, "color", $$v)},expression:"editLabel.color"}}),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-check")]),(_vm.mode == 'create')?_c('span',[_vm._v("Create")]):_c('span',[_vm._v("Update")])],1)],1),_c('div',{staticClass:"grow flex flex-col pt-4",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.clearSelection.apply(null, arguments)}}},[_c('p',{staticClass:"text-gray-500"},[_vm._v(_vm._s(_vm.labels.length)+" Labels.")]),_c('div',{staticClass:"grow flex flex-row flex-wrap gap-4 overflow-y-auto pb-8 place-content-start",staticStyle:{"height":"calc(100vh - 230px)"}},[(_vm.labels.length > 0)?[_vm._l((_vm.labels),function(label){return [_c('div',{key:label.text,class:[
                'border border-2 py-0 px-0.5 flex shrink items-center rounded',
                (_vm.selectedLabel && _vm.selectedLabel.id == label.id) ? 'border-red-400' : ''
              ],staticStyle:{"height":"60px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSelectLabel(label)}}},[_c('v-chip',{staticClass:"ma-0 px-4",attrs:{"close":"","large":"","label":"","color":label.color},on:{"click:close":function($event){return _vm.remove(label)}}},[_c('div',{class:['flex flex-row space-x-2 items-center', _vm.invertColorClass(label.color)]},[_c('span',[_vm._v(_vm._s(label.text))]),_c('span',{staticClass:"text-xs"},[_vm._v("("+_vm._s(label.notesCount)+" Notes)")])])])],1)]})]:[_c('span',{staticClass:"text-gray-500"},[_vm._v("No labels yet. Create one with the input above.")])]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="w-full h-full bg-gray-100 overflow-hidden">
    <div class="flex flex-col space-y-2 w-2/3 mx-auto mt-4 bg-white px-8 py-4">
      <div class="text-lg text-gray-500">Manage all labels</div>
      <div class="flex flex-row items-center space-x-2">
        <v-text-field
          placeholder="Type label text"
          outlined
          dense
          clearable
          hide-details
          v-model="editLabel.text"
          :style="{
            'background-color': editLabel.color
          }"
          :class="[darkText ? 'dark-text-input' : 'light-text-input']"
          @keydown.enter.stop.prevent="createNewLabel"
          @keydown.esc.stop.prevent="clearInput"
          >
        </v-text-field>
        <color-picker
          v-model="editLabel.color"
          >
          <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on">
              <v-icon>mdi-format-color-fill</v-icon>
              <span>Background color</span>
            </v-btn>
          </template>
        </color-picker>
        <v-btn
          outlined
          @click.stop.prevent="submit">
          <v-icon>mdi-check</v-icon>
          <span v-if="mode == 'create'">Create</span>
          <span v-else>Update</span>
        </v-btn>
      </div>
      <div
        class="grow flex flex-col pt-4"
        @click.stop.prevent="clearSelection"
        >
        <p class="text-gray-500">{{ labels.length }} Labels.</p>
        <div
          class="grow flex flex-row flex-wrap gap-4 overflow-y-auto pb-8 place-content-start"
          style="height: calc(100vh - 230px)">
          <template v-if="labels.length > 0">
            <template v-for="label in labels">
              <div
                :key="label.text"
                :class="[
                  'border border-2 py-0 px-0.5 flex shrink items-center rounded',
                  (selectedLabel && selectedLabel.id == label.id) ? 'border-red-400' : ''
                ]"
                style="height:60px"
                @click.stop.prevent="toggleSelectLabel(label)">
                <v-chip
                  class="ma-0 px-4"
                  close
                  large
                  label
                  :color="label.color"
                  @click:close="remove(label)"
                >
                  <div
                    :class="['flex flex-row space-x-2 items-center', invertColorClass(label.color)]"
                    >
                    <span>{{ label.text }}</span>
                    <span class="text-xs">({{ label.notesCount }} Notes)</span>
                  </div>
                </v-chip>
              </div>
            </template>
          </template>
          <template v-else>
            <span class="text-gray-500">No labels yet. Create one with the input above.</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ColorPicker from '@/components/colorpicker'
import invertColor from 'invert-color'

export default {
  name: 'LabelsView',

  components: {
    ColorPicker
  },

  data() {
    return {
      editLabel: {
        text: '',
        color: '#ffffff'
      },
      selectedLabel: null,
      mode: 'create'
    }
  },

  computed: {
    labels() {
      return this.$store.state.labels.items
    },

    darkText() {
      let color = invertColor(this.editLabel.color, true)
      if (color === '#ffffff') {
        return false
      }
      return true
    }
  },

  methods: {
    async createNewLabel() {
      try {
        if ( _.isEmpty(this.editLabel.text) ) {
          throw new Error('Label text must not be empty')
        }
        let label = this.$api.newObjectWithData('Label', this.editLabel)
        await label.save()
        this.clearInput()
        this.$store.commit('labels/addLabel', label)
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    async updateLabel() {
      try {
        if ( _.isEmpty(this.editLabel.text) ) {
          throw new Error('Label text must not be empty')
        }
        if (!this.selectedLabel) {
          return
        }

        this.selectedLabel.set('text', this.editLabel.text)
        this.selectedLabel.set('color', this.editLabel.color)
        await this.selectedLabel.save()
        this.clearSelection()
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    clearInput() {
      this.editLabel.text = ''
      this.editLabel.color = '#ffffff'
    },

    async remove(label) {
      try {
        let yes = await this.$confirm('Are you sure to remove the label? It is undoable, but all the notes with the label will NOT be affected.')
        if (yes) {
          await label.destroy()
          // let index = _.findIndex(this.labels, (item) => item.id == label.id)
          // if (index > -1) {
          //   this.labels.splice(index, 1)
          // }

          this.$store.commit('labels/removeLabel', label)
          if (this.selectedLabel && this.selectedLabel.id == label.id) {
            this.clearSelection()
          }
        }
      } catch(error) {
        console.error(error)
        await this.$notify(error.message)
      }
    },

    invertColorClass(color) {
      let localColor = invertColor(color, true)
      console.log(localColor)
      if (localColor === '#ffffff') {
        return 'text-white'
      }
      return 'text-black'
    },

    submit() {
      if (this.mode == 'create') {
        this.createNewLabel()
      } else {
        this.updateLabel()
      }
    },

    toggleSelectLabel(label) {
      if (this.selectedLabel && this.selectedLabel.id == label.id) {
        this.selectedLabel = null
      } else {
        this.selectedLabel = label
      }

      if (this.selectedLabel) {
        this.editLabel.text = this.selectedLabel.text
        this.editLabel.color = this.selectedLabel.color
        this.mode = 'update'
      } else {
        this.clearSelection()
      }
    },

    clearSelection() {
      this.mode = 'create'
      this.selectedLabel = null
      this.clearInput()
    }
  },

  async mounted() {
    try {
      await this.$store.dispatch('labels/load')
    } catch(error) {
      console.log(error)
      if (error.message.indexOf('Permission denied') > -1) {
        this.$router.push({ name: 'login', query: { ref: 'user-labels' } })
      }
    }
  },
}
</script>

<style>

.dark-text-input input {
  color: black !important;
}

.light-text-input input {
  color: white !important;
}

</style>